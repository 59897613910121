import { ClearSkipSmartMeterIntegration } from 'components/ClearSkipSmartMeterIntegration';
import { HousePagesRedirect } from 'components/HousePagesRedirect';
import { OnlyPrivateRoute } from 'components/OnlyPrivateRoute';
import { OnlyPublicRoute } from 'components/OnlyPublicRoute';
import { UserSetupRedirectRoute } from 'components/UserSetupRedirectRoute';
import { NavbarLayout } from 'layouts/NavbarLayout';
import { AccountPage } from 'pages/account/AccountPage';
import { AlertsPage } from 'pages/alerts/AlertsPage';
import { AlexaCallbackPage } from 'pages/alexa/AlexaCallbackPage';
import { AlexaIntegrationPage } from 'pages/alexa/AlexaIntegrationPage';
import { AlexaSettingsPage } from 'pages/alexa/AlexaSettingsPage';
import { AnalysisPage } from 'pages/analysis/AnalysisPage';
import { ForgotPasswordPage } from 'pages/auth/ForgotPasswordPage';
import { LoginForAlexaPage } from 'pages/auth/LoginForAlexaPage';
import { LoginPage } from 'pages/auth/LoginPage';
import { LoginWithTokenPage } from 'pages/auth/LoginWithTokenPage';
import { LogoutPage } from 'pages/auth/LogoutPage';
import { SignupPage } from 'pages/auth/SignupPage';
import { ContactPage } from 'pages/contact/ContactPage';
import { ContractPage } from 'pages/contract/ContractPage';
import { NotFoundPage } from 'pages/errors/NotFoundPage';
import { ExpectationsPage } from 'pages/expectations/ExpectationsPage';
import { HomePage } from 'pages/home/HomePage';
import { HouseSettingsPage } from 'pages/house-settings/HouseSettingsPage';
import { OnBoardingHouseSettingsPage } from 'pages/house-settings/OnBoardingHouseSettingsPage';
import { IntegrationsPage } from 'pages/integrations/IntegrationsPage';
import { InvoicesPage } from 'pages/invoices/InvoicesPage';
import { OnBoardingNotificationsPage } from 'pages/notifications/OnBoardingNotificationsPage';
import { OptimizePage } from 'pages/optimize/OptimizePage';
import { ProfilePage } from 'pages/profile/ProfilePage';
import { SmartDevicePage } from 'pages/smart-devices/SmartDevicePage';
import { SmartDevicesPage } from 'pages/smart-devices/SmartDevicesPage';
import { SmartMeterIntegrationPage } from 'pages/smart-meter/SmartMeterIntegrationPage';
import { SupportPage } from 'pages/support/SupportPage';
import { SupportTicketCreatePage } from 'pages/support/SupportTicketCreatePage';
import { SupportTicketPage } from 'pages/support/SupportTicketPage';
import { SupportTicketRespondPage } from 'pages/support/SupportTicketRespondPage';
import { SupportTicketSuccessPage } from 'pages/support/SupportTicketSuccessPage';
import { SupportTicketsPage } from 'pages/support/SupportTicketsPage';
import { Route, Routes } from 'react-router-dom';

export const AppRoutes = () => {
  return (
    <>
      <Routes>
        {/* Public pages */}
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="/login-with-token" element={<LoginWithTokenPage />} />
        <Route path="/login-for-alexa" element={<LoginForAlexaPage />} />

        {/* Pages only reachable when user isn't authenticated */}
        <Route element={<OnlyPublicRoute />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/signup" element={<SignupPage />} />
        </Route>

        {/* Pages only reachable when user is authenticated */}
        <Route element={<OnlyPrivateRoute />}>
          <Route element={<UserSetupRedirectRoute />}>
            {/* Private pages with tabs layout */}
            <Route element={<NavbarLayout />}>
              {/* Common routes */}
              <Route path="/account" element={<AccountPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/integrations" element={<IntegrationsPage />} />

              {/* Alexa routes (with navbar layout) */}
              <Route path="/alexa" element={<AlexaIntegrationPage />} />
              {/* Alias of /alexa */}
              <Route
                path="/profile/integrations/alexa"
                element={<AlexaIntegrationPage />}
              />
              <Route path="/alexa/callback" element={<AlexaCallbackPage />} />
              {/* Alias of /alexa/callback */}
              <Route
                path="/profile/integrations/alexa/callback"
                element={<AlexaCallbackPage />}
              />

              {/* For no houses user */}
              <Route element={<HousePagesRedirect />}>
                <Route index element={<HomePage />} />
                <Route path="/analysis" element={<AnalysisPage />} />
                <Route path="/optimize" element={<OptimizePage />} />
                <Route path="/alerts" element={<AlertsPage />} />
                <Route path="/invoices" element={<InvoicesPage />} />
                <Route path="/contract" element={<ContractPage />} />
                <Route path="/settings" element={<HouseSettingsPage />} />
                <Route path="/smart-devices" element={<SmartDevicesPage />} />
              </Route>

              {/* For user with houses */}
              <Route path="/houses">
                <Route index element={<NotFoundPage />} />
                <Route path=":houseId">
                  <Route index element={<HomePage />} />
                  <Route path="analysis" element={<AnalysisPage />} />
                  <Route path="optimize" element={<OptimizePage />} />
                  <Route path="invoices" element={<InvoicesPage />} />
                  <Route path="settings" element={<HouseSettingsPage />} />
                  <Route path="contract" element={<ContractPage />} />
                  <Route path="alerts" element={<AlertsPage />} />
                  {/* Smart devices */}
                  <Route path="smart-devices">
                    <Route index element={<SmartDevicesPage />} />
                    <Route
                      path=":deviceId/:channel"
                      element={<SmartDevicePage />}
                    />
                  </Route>
                </Route>
              </Route>

              {/* Support */}
              <Route path="/support">
                <Route index element={<SupportPage />} />
                <Route path="tickets">
                  <Route index element={<SupportTicketsPage />} />
                  <Route path="create" element={<SupportTicketCreatePage />} />
                  <Route path=":ticketId">
                    <Route index element={<SupportTicketPage />} />
                    <Route
                      path="submit-respond"
                      element={<SupportTicketRespondPage />}
                    />
                  </Route>
                  <Route
                    path="success"
                    element={<SupportTicketSuccessPage />}
                  />
                </Route>
              </Route>
            </Route>

            {/* Private pages with default layout */}
            <Route element={<ExpectationsPage />} path={'/expectations'} />

            <Route
              element={<OnBoardingHouseSettingsPage />}
              path={'/onboarding-house-settings'}
            />

            <Route
              element={<OnBoardingNotificationsPage />}
              path={'/notifications'}
            />

            <Route path="/smart-meter">
              <Route index element={<SmartMeterIntegrationPage />} />
              <Route
                path="force"
                element={<ClearSkipSmartMeterIntegration />}
              />
            </Route>

            {/* Alexa routes (no layout) */}
            <Route path="/alexa/settings" element={<AlexaSettingsPage />} />
          </Route>
        </Route>

        {/* 404 page */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};
