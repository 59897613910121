import LeadCaptureSuccessIllustration from '../assets/lead-capture-success.svg?react';
import { Button } from '@clevergy/ui/components/Button';
import { FC } from 'react';

export type LeadCaptureSuccessProps = {
  onClick: () => void;
};

export const LeadCaptureSuccess: FC<LeadCaptureSuccessProps> = ({
  onClick,
}) => {
  return (
    <div className="flex flex-col justify-center items-center h-full text-white text-center gap-4">
      <div>
        <h2 className="text-xl mb-1">¡Perfecto!</h2>
        <p>
          Muy pronto nos pondremos en contacto contigo para hacerte una
          recomendación personalizada.
        </p>
      </div>
      <LeadCaptureSuccessIllustration />
      <Button
        colorScheme="primary"
        variant={'filled'}
        onClick={onClick}
        fullWidth
      >
        Finalizar
      </Button>
    </div>
  );
};
