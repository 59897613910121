import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { AppHeader } from 'components/AppHeader';
import { HousePicker } from 'components/HousePicker';
import { Wrapper } from 'components/Wrapper';
import { useAuthContext } from 'context/AuthContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const ContractPage: FC = () => {
  const { t } = useTranslation();
  const { token } = useAuthContext();
  const { selectedHouse } = useSelectedHouse();

  return (
    <>
      <AppHeader backButton title={t('contract.title') as string}>
        <HousePicker />
      </AppHeader>
      <Wrapper>
        {(!token || !selectedHouse) && (
          <div className="fixed inset-0 flex items-center justify-center">
            <LoadingIndicator />
          </div>
        )}

        {token && selectedHouse && (
          <div className="flex flex-col gap-4">
            <clevergy-house-contract
              data-token={token}
              data-house-id={selectedHouse.houseId}
            />
          </div>
        )}
      </Wrapper>
    </>
  );
};
